.about__content {
    margin-top: 3rem;
    display: grid;
    grid-template-columns: 50% 50%;
    gap: 2rem;
}

.about__experience, .about__linkedin{
    text-align: left;
    padding: 2rem;
    transition: var(--transition);
}

.about__experience {
    border-right: 1px solid var(--color-bg-variant);
}

.expertise__content {
    margin-top: 3rem;
    display: grid;
    grid-template-columns: 1fr;
    gap: 2rem;
}

.expertise__content h3 {
    font-weight: 300;
}

h1 {
    font-weight: 400;
}

.experience__content {
    margin-top: 1rem;
    display: grid;
    gap: 1rem;
}

.card__title {
    display: flex;
    gap: 0.5rem;
    align-items: center;
}

.experience__icons {
    font-size: 1.5rem;
}

.experience__card p {
    padding: 1rem;
    font-weight: 300;
    text-align: justify;
}

#aol {
    font-size: 2rem;
}

/* Medium Devices */

@media screen and (max-width: 1024px) {
    .about__content {
        grid-template-columns: 1fr;
    }

    .about__experience {
        border-right: 0px transparent;
        border-bottom: 1px solid var(--color-bg-variant);
    }
}

/* Small Devices */

@media screen and (max-width: 600px) {
    .about__content {
        grid-template-columns: 1fr;
    }
    .experience__card p {
        text-align: left;
        font-size: 1rem;
    }

    h2 {
        font-size: 1.1rem;
    }
}