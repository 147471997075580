@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@200;300;400;500;600&display=swap');

* {
    margin: 0;
    padding: 0;
    border: 0;
    outline: 0;
    box-sizing: border-box;
    list-style-type: none;
    text-decoration: none;
    font-weight: 200;
}

:root {
    --color-bg: #E5E7E9;
    --color-bg-variant: #173753;
    --color-white: #fbfcff;
    --color-bg-light: #1c6e8c;

    --transition: all 400ms ease;
}

html {
    scroll-behavior: smooth;
    scroll-padding-top: var(--scroll-padding-top, 100px);
}

::-webkit-scrollbar {
    display: none;
}

body {
    font-family: 'Poppins', sans-serif;
    background: var(--color-bg);
}

.container {
    width: 85%;
    margin: 0 auto;
}

section {
    margin-top: 5rem;
}

a {
    text-decoration: none;
    color: var(--text-primary);
    transition: var(--transition);
}

.btn {
    width: max-content;
    background: #00a6a6;
    color: var(--color-white);
    padding: 0.8rem;
    border-radius: 0.5rem;
}

.btn:hover {
    background: var(--color-bg-light);
}


@media screen and (max-width: 600px) {
    .container {
        width: 95%;
    }
}