.nav__container {
    width: 100%;
    position: sticky;
    background: var(--color-bg-variant);
    overflow: hidden;
    top: 0;
    padding: 1rem;
    display: flex;
    align-items: center;
    padding: 1.5rem;
}

#mobile_a, #mobile_s, #mobile_t, #mobile_c {
    display: none;
}

.section__links {
    display: flex;
    gap: 3rem;
    margin-left: 1rem;
}

.section__links a {
    color: var(--color-white);
    transition: var(--transition);
    padding-bottom: 3px;
    border-bottom: 1px solid transparent;
}

.section__links a:hover {
    cursor: pointer;
    border-bottom-color: var(--color-white);
}

.nav__download {
    position: fixed;
    right: 3rem;
}

.section__links a.active {
    border-bottom-color: var(--color-white);
}

/* Medium Devices */

@media screen and (max-width: 1024px) {
    .nav__download {
        display: none;
    }
}

/* Small Devices */

@media screen and (max-width: 600px) {
    #mobile_a, #mobile_s, #mobile_t, #mobile_c {
        display: block;
    }

    #pc_a, #pc_s, #pc_t, #pc_c {
        display: none;
    }
}