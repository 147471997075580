.testimonials__container h1 {
    text-align: center;
}

.testimonials__content {
    display: grid;
    grid-template-columns: 50% 50%;
    gap: 2rem;
    margin-top: 2rem;
    filter: blur(4px);
}

.testimonial__card {
    padding: 2rem;
    text-align: justify;
    display: flex;
    flex-direction: column;
    gap: 1rem;
}

.testimonial__card p {
    font-weight: 300;
}

#card1 {
    border-right: 1px solid var(--color-bg-variant);
    padding-right: 3.5rem;
}

/* Medium Devices */

@media screen and (max-width: 1024px) {
    .testimonials__content {
        grid-template-columns: 1fr;
    }

    #card1 {
        border-right: 0px transparent;
        border-bottom: 1px solid var(--color-bg-variant);
    }
}