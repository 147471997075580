.container.header__container {
    width: 100%;
    height: 65vh;
    display: grid;
    grid-template-columns: 50% 35%;
    padding: 2rem;
    background: linear-gradient(45deg, #F8C471, #598392);
    gap: 3rem;
    justify-content: center;
    overflow: hidden;
    align-items: center;
}

.header__img img {
    width: 100%;
    border-radius: 0.5rem;
}

.header__phrase {
    padding: 2rem;
}

.splash {
    margin-top: 0.5rem;
    display: flex;
    gap: 1rem;
    align-items: center;
}

.header__icon {
    margin-top: 0.5rem;
    font-size: 4rem;
    border-radius: 1rem;
    padding: 0.2rem;
    background: #fbf6ef;
}

/* Medium Devices */

@media screen and (max-width: 1024px) {
    .container.header__container {
        height: 55vh;
        grid-template-columns: 1fr;
        text-align: center;
        gap: 0rem;
        font-size: smaller;
        padding: 0rem;
    }
    .splash {
        gap: 0.5rem;
        flex-direction: column;
    }
    .header__img img {
        width: 20rem;
    }
    .header__img {
        margin-top: -2rem;
    }
}

/* Small Devices */

@media screen and (max-width: 600px) {
    .container.header__container{
        height: 75vh;
    }
    .header__img img{
        width: 13rem;
    }
}


/* Small Devices in landscape mode */

@media screen and (max-width: 916px) and (orientation: landscape) {
    .container.header__container {
        height: 110vh;
    }

    .header__img img {
        width: 10rem;
    }
}



