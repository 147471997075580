.footer__container {
    background: var(--color-bg-variant);
    color: var(--color-white);
    height: 55vh;
    padding: 2rem;
    text-align: center;
}

.footer__container h1 {
    font-weight: 300;
}

.footer__links {
    margin: auto;
    justify-content: center;
    text-align: center;
    width: 10%;
    display: grid;
    grid-template-columns: 1fr 1fr;
    margin-top: 1rem;
    font-size: 2rem;
    gap: 1rem;
}

/* Medium Devices */

@media screen and (max-width: 1024px) {
    .footer__container {
        height: 30vh;
    }
}

@media screen and (max-width: 600px) {
    .footer__container {
        height: 60vh;
    }
}

@media screen and (max-width: 916px) and (orientation: landscape) {
    .footer__container {
        height: 80vh;
    }
}