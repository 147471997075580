.contact__container {
    padding: 0 4rem 0 4rem;
}

form {
    margin-top: 1rem;
    display: flex;
    flex-direction: column;
    gap: 1rem;
}

input, textarea {
    border-radius: 2rem;
    background: #d0ccd0;
    padding: 1rem;
    resize: none;
}

button {
    width: max-content;
    padding: 1rem;
    background: var(--color-bg-light);
    border-radius: 1rem;
    color: var(--color-white);
    transition: var(--transition);
}

button:hover{
    cursor: pointer;
    background: var(--color-hover);
}

.contact__content {
    display: grid;
    grid-template-columns: 1fr 1fr;
}

.contact__container-title {
    display: flex;
    gap: 0.5rem;
}

.contact__container-icon {
    margin-top: 6.5px;
    font-size: 1.5rem;
}

.contact__further {
    display: grid;
    grid-template-columns: 1fr 1fr;
}

.contact__further div {
    margin: auto;
    background: #00a6a6;
    width: 15rem;
    height: 10rem;
    border-radius: 1rem;
    padding: 2rem;
    color: var(--color-white);
    display: flex;
    flex-direction: column;
    gap: 0.5rem;
    text-align: center;
}

.contact__further h3 {
    font-weight: 400;
}

.contact__further a {
    font-size: 2rem;
}

.contact__container a:hover {
    color: #0072b1;
    font-size: 2.5rem;
}

/* Medium Devices */

@media screen and (max-width: 1024px) {
    .contact__content {
        grid-template-columns: 1fr;
    }

    .contact__further {
        margin-top: 2rem;
    }
}

@media screen and (max-width: 600px) {
    .contact__container{
        padding: 1rem 1rem 1rem 1rem;
    }

    form {
        width: 20rem;
        margin: 1rem auto;
    }

    .contact__further {
        grid-template-columns: 1fr;
        gap: 1rem;
    }

}